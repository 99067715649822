import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';


const translationsJa = {
    welcome: "こんにちは!",
    episodes: "エピソード",
    characters: "登場人物",
    songs: "曲",
    aboutSite: "このサイトについて",
    aboutSiteExplanation: "当サイトは、ドラマ「ドクターX」のファン個人による非公式ファンサイトです。",
    officialSite: "公式サイト",
    sitemap: "サイトマップ",
    contact: "お問い合わせ",
    season: "シーズン",
    airTime: "放送時間",
    minutes: "分",
    airDate: "放送日",
    story: "ストーリー",
    1.1: "大学病院にフリーランスの外科医・大門未知子が補充要員としてやって来た。新人外科医の森本は、前夜、クラブで急患を処置する未知子を目撃していた。",
    1.2: "有名出版社の社長・二階堂が入院してくる。社会的影響力の強い二階堂を毒島新院長らはVIP待遇で迎えるが、情若無人な態度に未知子は不満を隠せない。",
    1.3: "食道がんで入院した料理研究家の花山三恵が、医局で自慢の手料理を振る舞うが、味は微妙。加地や原はお世辞を言うが、未知子は「まずい」と一刀両断。",
    1.4: '第二外科の敏腕ドクター加地が、スーパードクターを紹介するテレビ番組に登場。”腹腔(ふくくう)鏡の魔術師"として紹介され、院長や事務長も満足顔。',
    1.5: "院内で、ある少女に足の手術を懇願される未知子。担当が違う上に子供が得意ではない未知子だったが、小児外科の主治医・岸田のところに送り届ける。",
    1.6: "名医紹介所に、医者になる前の未知子を知る六坂が訪ねてくる。六坂は10年前、未知子の父に肝臓がんの手術を施され、おかげで命拾いをしたという。",
    1.7: "肝門部胆管がんが発覚した第二外科部長の鳥井。未知子から一刻を争う病状と指摘されたにもかかわらず、国際会議での論文発表に向け、執筆に没頭する。",
    1.8: "鳥井の肝門部胆管がん手術に挑む未知子を、加地や森本らが助手として支える。見学室に大勢のプレスが集まるなか、本院の勅使河原主任教授が姿を現す。",
    2.1: "旭川で競馬に興じる未知子。レース中に馬が転倒し、その馬主のマダムが嘆いている状況に出くわす。未知子は、したこともない馬の手術を引き受けるが!?",
    2.2: "蛭間外科統括部長が執刀した不動産王に、がんの再発が発覚。手術ミスの可能性を認めるわけにいかない蛭間。新たな初期のがんの発現とウソの告知をする。",
    2.3: "教授夫人会の会長・蛭間華子が、夫の蛭間を訪ねて来た。華子は、海老名教授の執刀で1年前に小腸の手術を受けた蟹江外科教授夫人の再手術を要請する。",
    2.4: '各界に太いパイプを持つ"銀座の女帝"四条留美子は医学界にも通じ、蛭間の出世に一枚かんでいるらしい。そんな留美子にすい臓の進行がんが見つかる。',
    2.5: "世界的外科医の日下部を招へいした蛭間。天才ドクターとのつながりを宣伝して自分の株を上げるため、蛭間は日下部による公開手術のライブ中継を計画。",
    2.6: "近藤の父が大学病院を訪ねて来る。競馬と酒が趣味の父は、ステータスを尊ぶ大学病院で出世を目論む近藤にとってアキレス腱(けん)のような存在だった。",
    2.7: "蛭間の推薦により近藤がついに教授となり、海老名教授を抜いて次期主任教授の本命に躍り出る。だが、馬淵内科統括部長の策路でライバルが出現する。",
    2.8: "謎の依頼王からの要請で、帝都医大・高松第24分院に派遣される未知子。病院近くの漁港で、漁師にあこがれる入院患者の少女・九留美と出会う。",
    2.9: "九留美を救うため、未知子は日本で前例のない難手術を提案。未知子といえど、今度ばかりは帝都医大の設備と組織力が成功の絶対条件だが....。",
    3.1: "南の島で、豪華客胎の事故で負傷した乗客たちに遭遇する未知子。その中に、かつて派遣された帝都医科大学付属第三病院の元院長・毒島の姿もあった。",
    3.2: '国立高度医療センターには、派閥同士の確執が依然として存在する。そこに"ノーベル賞に最も近い日本人"といわれる物理学者・風間が入院してくる。',
    3.3: "国立高度医療センターに日本看護師連合会の会長・三原雅恵が現れた。医学界に名をとどろかせ、総長の天堂も一目置く彼女が同センターを訪れた理由とは。",
    3.4: "討論番組に出演した天堂は、ジャーナリストの四方と対立。四方は、国立高度医療センターの優秀さを示したければ自分のがんを治せと天堂に宣戦布告する。",
    3.5: "覇権争いが絶えないなか、天堂は戦路統合外科部長を海老名か談合坂のどちらかに絞ると表明。その頃、経産省の大物・五十嵐が結腸がんで入院してくる。",
    3.6: "天堂が提唱する国家レベルの新組織実現のカギを握る厚労大臣から、秘密裏の依頼が来る。IT企業社長の六甲をしばらく院内で預かってほしいというのだ。",
    3.7: "ナイトクラブにボディコンギャル集団を引き連れた騒がしい男が出現。男は、心肺停止に陥ったギャルに鮮やかな応急処置を施した。一体、何者なのか!?",
    3.8: "肺静脈閉塞症の小学生ちひろが命の危機にさらされていることに気づく未知子。だが、富士川や他の医局員たちは手の施しようのない状態と判断していた。",
    3.9: "未知子は街中でスーパーモデルの真耶と遭遇。自分と同じ服を着ている未知子に、真耶は敵意をむき出しに。その真耶が後日、国立高度医療センターに来る。",
    3.11: "次期厚生労働大臣と目される十勝喜子が入院。新組織創立の実現に大きな意味を持つ手術のため、富士川を中心に特別治療プロジェクトチームが結成される。",
    3.12: "神原晶が人知れず倒れ、しかも未知子の前から姿を消してしまう。同じ頃、天堂が夢見ていた国家レベルの新組織、日本医療産業機構がついに発足する。",
    4.1: "NYの中華料理店を訪れた未知子の目前で、客の妊・が突然倒れる。未知子は客のひとり、久保東子が働く病院で、迅速かつ的確な処置で患者の命を救う。",
    4.2: "蛭間が富裕層に特化した戦路を打ち出す。見るからに貧しそうな二岡夫・が来院するも、黄川田に一蹴される。国会議員の金本が検査のため極秘入院する。",
    4.3: "未知子が財布を捻った縁で、須山三佐江という女性が最高ランクの人間ドックを予約。副院長の東子は、素性不明の女の予約を勝手に入れた未知子に激怒！",
    4.4: "カリスマ美人ユニットの四葉姉妹が病院に来るが、未知子は思わず目を疑う。そこにいたのは美しい姉・美麗と、見る影もなく太った妹・美音だった。",
    4.5: "格下の大学病院による研究論文が米国の医学雑誌に掲載され、世界の注目を集める。蛭間は、東帝大学病院の医師たちに論文を書きまくるよう強要する。",
    4.6: "内科の赤井が米国のスターキー教授の論文を盗用した問題で、蛭間は辞任を迫られる。だが、蛭間は会見にスターキー教授を呼び寄せ、盗用を否定する。",
    4.7: "耳が聴こえないピアニストとして人気を集める七尾貴志のリサイタルに来た神原と未知子。七尾のアシスタントの由香は、彼の耳の治療を未知子に依頼する。",
    4.8: "人気絶頂のスーパースター八乙女悠太が、表舞台から姿を消す。実は、手術が極めて難しい肝臓がんを患い、東帝大学病院に極秘入院していたのだ。",
    4.9: '東帝大学病院とライバル関係にあった慶林大学病院が、最新の病院ランキングで1位を獲得。蛭間は"打倒慶林大"を合言葉に、医師たちに発硪をかける。',
    4.11: "未知子らは神社で、西園寺外科部長が妻ではなさそうな妊婦に安産のお守りを買う光景を目撃する。後日、入院してきたその妊娠は中国の大財閥夫人と判明。",
    4.12: "局所進行膵(すい)がんを隠していた博美がついに倒れる。完全切除が不可能と自覚している博美は、娘の舞に病気がバレないように協力を未知子に頼む。",
    5.1: "未知子が乗っていたバスの運転手が心肺停止に陥ったため、医師が一人で営む病院に運び込む。その医師とは、かって未知子にあこがれていた森本だった。",
    5.2: '蛭間が院長に返り咲き、"白い巨塔"は再び金と欲にまみれた組織に。西山や伊東らゆとり世代の医師は、堂々と院長回診をすっぽかすなど我が道を買く。',
    5.3: "東帝大学付属第八幼稚園の園長・三鴨が倒れ、偶然その場に居合わせた未知子が東帝大学病院へ搬送する。実は三鴨は、元々外科副部長・猪又の患者だった。",
    5.4: "婚活パーティーに参加した森本は、そこで出会った女性と婚約する仲にまで発展。ところが幸せ気分も束の間、女性は突然、森本の目の前で倒れてしまう！",
    5.5: "日本医師倶楽部会長の内神田は、最新のAIl診断システムを使った症例を上げるよう提言。蛭間はさっそく従おうとするが、鳥井は懐疑的な姿勢を見せる。",
    5.6: "患者から術後経過についてクレームを受けた猪又が、何の非もない博美のせいにして責任逃れ。しかも猪又の根回しによって、博美は病院を出入禁止になる。",
    5.7: "内神田の妻・小百合が、従妹の見合い相手を医局から見構ってほしいと蛭間に依頼してくる。白羽の矢が立った原は、見合いに意気込むが！？",
    5.8: "元厚労大臣秘書官・八雲が入院する。肝外発育型肝細胞がんで死を覚悟する彼は、ある大学病院の補助金不正使用を厚労省が隠べいした事実を告発していた。",
    5.9: "バレエの選考会を控える選が、足の検査を受診。幸い大事に至らない炎症と判明するが、未知子は運動能力に支障をきたす舟状骨骨折の可能性を示唆する。",
    5.11: "未知子が倒れ、神原や博美も気が気でない。一方、内神田が食道がんの手術を受けることになり、未知子以外にオペの成功は不可能な状態なのだが...。",
    6.1: "山奥で1の家にたどり着いた未知子は、1人の日系ブラジル人と意気投合。その頃、東帝大学病院は財政危機にひんし、海外投資ファンドにすがることに。",
    6.2: "ニシキグループのCEO、二色寿郎は肝移植の手術を希望する。成功した暁にはファンドに10億円の出資を約束すると言うが、ドナーが見つからない。",
    6.3: "大臣の梅沢がマスコミから逃れるため入院を希望するが二コラスに断られる。梅沢の秘書は蛭間を訪れ、不起訴にする代わりに梅沢をかくまうよう打診する。",
    6.4: "隆上界のエース、四日市が右足の痛みを訴え極秘入院。次世代インテリジェンス手術担当外科部長の潮がAIによる診断を実施した結果、滑膜肉腫と判明。",
    6.5: "日本看護師連合会名誉会長の三原を迎え、看護師のための講演会が開かれる。しかし内容があまりに時代錯誤だったため、浜地らが途中で打ち切りにする。",
    6.6: "若き実業家の六角橋が、7歳の少女の難手術を未知子に依頼。「お金はいくらでも払う」と宣言し、その過程をSNSにアップするが、実は売名行為だった。",
    6.7: '世界的銀行家のゴールドバーグが最先端の"植毛"を未知子に依頼。だが未知子の失礼な言葉に怒り帰ろうとしたその時、みぞおちを押さえて倒れてしまう。',
    6.8: "蛭間の策路によりニコラスが逮捕される。病院に康清の嵐が吹き荒れるといううわさに丹下派の潮と浜地が戦々恐々とする中、外科医の中山麻里亜が現れる。",
    6.9: "日本が誇るロックスターの九藤と、若きシンガーソングライターの新津が入院。新津がVIP用の特別室にいることを九藤が知り、気まずい空気が流れる。",
    6.11: "重病に侵された幼き天才ピアニスト、吉行和十が入院する。ピアノが弾けなくなるかもしれないという不安を抱えながら、未知子を信じ手術に臨むが...。",
    soundtrack: "サウンドトラック",
    outro: "主題歌",
    otherSongs: "その他",
    lyrics: "歌詞",
    voiceOver: "冒頭ナレーション",
    location: "ロケ地"
}
const translationsEn = {
    welcome: "Welcome!",
    episodes: "Episodes",
    characters: "Characters",
    songs: "Songs",
    aboutSite: "About This Site",
    aboutSiteExplanation: "This site is",
    officialSite: "Official Site",
    sitemap: "Sitemap",
    contact: "Contact",
    season: "Season",
    airTime: "Air Time",
    minutes: "minutes",
    airDate: "Air Date",
    story: "Story",
    1.1: "",
    1.2: "",
    1.3: "",
    1.4: "",
    1.5: "",
    1.6: "",
    1.7: "",
    1.8: "",
    2.1: "",
    2.2: "",
    2.3: "",
    2.4: "",
    2.5: "",
    2.6: "",
    2.7: "",
    2.8: "",
    2.9: "",
    3.1: "",
    3.2: "",
    3.3: "",
    3.4: "",
    3.5: "",
    3.6: "",
    3.7: "",
    3.8: "",
    3.9: "",
    3.10: "",
    3.11: "",
    4.1: "",
    4.2: "",
    4.3: "",
    4.4: "",
    4.5: "",
    4.6: "",
    4.7: "",
    4.8: "",
    4.9: "",
    4.10: "",
    4.11: "",
    5.1: "",
    5.2: "",
    5.3: "",
    5.4: "",
    5.5: "",
    5.6: "",
    5.7: "",
    5.8: "",
    5.9: "",
    5.10: "",
    6.1: "",
    6.2: "",
    6.3: "",
    6.4: "",
    6.5: "",
    6.6: "",
    6.7: "",
    6.8: "",
    6.9: "",
    6.10: "",
    soundtrack: "Soundtrack",
    outro: "Outro",
    otherSongs: "Other Songs",
    lyrics: "Lyrics",
    voiceOver: "Intro Voiceover",
    location: "Film Locations"
}
// en: {
//     translation: {
//       'ようこそ React と react-i18next へ。': 'Welcome to React and react-i18next.',
//       言語を切り替え: 'change language',
//     },
//   },
//   ja: {
//     translation: {
//       'ようこそ React と react-i18next へ。': 'ようこそ React と react-i18next へ。',
//       言語を切り替え: '言語を切り替え',
//     },
//   },


i18n.use(initReactI18next).init({
    resources: {
        ja: {translation: translationsJa},
        en: {translation: translationsEn}
    },
    lng: 'ja',
    fallbackLng: 'ja',
    interpolation: { escapeValue: false },
  });