import { t } from "i18next";
import SectionBanner from "../components/SectionBanner";

import lobby from "../images/lobby.jpg"

export default function VoiceOver() {
  return (
    <div className="voice-over">
      <SectionBanner bannerImage={lobby} section={t("voiceOver")}/>

      <p>
        これは一匹狼の女医の話である。 大学病院の医局は弱体化し、 命のやりと
        りをする医療もついに弱肉強食の時代に突入した。
        その危機的な医療現場の穴埋めに現れたのが
        フリーランス…すなわち、一匹狼のドクターである。 たとえば、この女。
        群れを嫌い、権威を嫌い、束縛を嫌い、
        専門医のライセンスと叩き上げのスキルだけが彼女の武器だ。
        外科医、大門未知子。またの名を、ドクター X。
      </p>

      <h3>{t("season")}1 OPE.1</h3>
      <p>
        　2012年白い巨塔は音を立てて崩れ落ちようとしている。大学病院の医局は弱体化し医者のヒエラルキーは崩壊命のやり取りをする医療もついに弱肉強食の時代に突入した。かつて花形だった外科医は過酷な労働条件から成り手が激減ジリ貧の医局を去る医者も少なくない。危機的な医療現場の穴埋めに全く新しいタイプの医者たちが登場した。医者の勤務体系には勤務医と開業医の2種類がある。かつて殆どの勤務医が大学病院の医局の人事権に支配されていたが大学病院の医局に属さないフリーランスすなわち一匹狼のドクターが現れたのである。例えばこの女、群れを嫌い権威を嫌い束縛を嫌い専門医のライセンスと叩き上げのスキルだけが彼女の武器だ。外科医・大門未知子またの名をドクターX。
      </p>
      <h3>{t("season")}1 OPE.2以降</h3>
      <p>
        　これは一匹狼の女医の話である。大学病院の医局は弱体化し命のやり取りをする医療もついに弱肉強食の時代に突入した。その危機的な医療現場の穴埋めに現れたのがフリーランスすなわち一匹狼のドクターが現れた。例えばこの女。群れを嫌い権威を嫌い束縛を嫌い専門医のライセンスと叩き上げのスキルだけが彼女の武器だ。外科医・大門未知子またの名をドクターX。
      </p>

      <h3>{t("season")}2 OPE.1</h3>
      <p>
        　これは一匹狼の女医の話である。2013年白い巨塔の崩壊は留まるところを知らず、危機的な医療現場の穴埋めに全く新しいタイプの医者たちが登場した。医者の勤務体系には勤務医と開業医の2種類がある。かつて殆どの勤務医が大学病院の医局の人事権に支配されていたが大学医局にも属さないフリーランスすなわち一匹狼のドクターが現れたのである。例えばこの女。群れを嫌い権威を嫌い束縛を嫌い専門医のスキルだけが彼女の武器だ。外科医・大門未知子またの名をドクターX。
      </p>
      <h3>{t("season")}2 OPE.2</h3>
      <p>
        　これは一匹狼の女医の話である。大学病院の医局は弱体化し命のやり取りをする医療もついに弱肉強食の時代に突入した。その危機的な医療現場の穴埋めに現れたのがフリーランスすなわち一匹狼のドクターである。例えばこの女。群れを嫌い権威を嫌い束縛を嫌い専門医のライセンスと叩き上げのスキルだけが彼女の武器だ。外科医・大門未知子またの名をドクターX。
      </p>
      <h3>{t("season")}3 OPE.1</h3>
      <p>
        　これは一匹狼の女医の話である。2014年白い巨塔の崩壊は留まるところを知らず、命のやり取りをする医療は本来あるべき姿を完全に見失っていたしかしそんな状況にも関わらず国は成長戦略の一環として日本の最新医療を積極的に輸出する方針を打ち出した。医学界はさらなる弱肉強食の時代に突入した。そんな中どこの大学医局にも属さないフリーランスすなわち一匹狼のドクターが現れた。例えばこの女。群れを嫌い権威を嫌い束縛を嫌い専門医のライセンスと叩き上げのスキルだけが彼女の武器だ。外科医・大門未知子またの名をドクターX。
      </p>
      <h3>{t("season")}4 OPE.1</h3>
      <p>
        　これは一匹狼の女医の話である。2016年白い巨塔の崩壊は留まるところを知らず命のやり取りをする医療は迷走を極めていた。有名大学病院がブランド力の強化に奔走し一方高いスキルを持つ外科医は高額な金で流出。医学界はさらなるグローバルな弱肉強食の時代に突入した。そんな中どこの組織にも属さないフリーランスすなわち一匹狼のドクターが現れた。例えばこの女。群れを嫌い権威を嫌い束縛を嫌い専門医のライセンスとたたき上げのスキルだけが彼女の武器だ。外科医・大門未知子またの名をドクターX。
      </p>
      <h3>{t("season")}5 OPE.1</h3>
      <p>
        　これは一匹狼の女医の話である。2017年、留まるところをしらず崩壊を続けた白い巨塔はついに崩れ落ち、大学病院は本来のあるべき姿を取り戻そうと動き出した。旧態依然の権力闘争を一掃し、患者を第一に考えた患者のための医療である。そんな中、どこの大学医局にも属さないフリーランス、すなわち一匹狼のドクターが現れた。例えばこの女。群れを嫌い、権威を嫌い、束縛を嫌い、専門医のライセンスと叩き上げのスキルだけが彼女の武器だ。外科医・大門未知子、またの名をドクターX。
      </p>
      <h3>{t("season")}5 OPE.2</h3>
      <p>
        　これは一匹狼の女医の話である。2017年、止まるところを知らず崩壊を続けた白い巨塔はついに崩れ落ち、大学病院は本来あるべき姿を取り戻そうと動き出した・・・かに見えたが、崩れ落ちたかのように見えた白い巨塔は、再びその権威を取り戻し、命のやり取りをする医療は本来あるべき姿を完全に見失った。そんな中、どこの大学医局にも属さないフリーランス、すなわち一匹狼のドクターが現れた。例えばこの女。群れを嫌い、権威を嫌い、束縛を嫌い、専門医のライセンスと叩き上げのスキルだけが彼女の武器だ。外科医・大門未知子、またの名をドクターX。
      </p>
      <h3>{t("season")}6 OPE.1</h3>
      <p>
        　これは一匹狼の女医の話である。2019年白い巨塔は完全に崩れ落ち日本の大学病院は巨額の赤字に追い込まれた。命のやり取りをする医療は経済的な弱肉強食の時代に突入した。そんな中どこの大学医局にも属さないフリーランスすなわち一匹狼のドクターが現れた。例えばこの女。群れを嫌い権威を嫌い束縛を嫌い専門医のライセンスと叩き上げのスキルだけが彼女の武器だ。外科医・大門未知子またの名をドクターX。
      </p>
    </div>
  );
}
